@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'leaflet.css';

html {
    height: -webkit-fill-available;
}

body {
    @apply min-h-screen;
    min-height: -webkit-fill-available;
}

.sidebar-button.active {
    @apply opacity-0 -translate-x-16;
}

.sidebar-container {
    @apply h-screen;
    height: calc(var(--vh, 1vh) * 100);
    min-width: 290px;
}

.sidebar-container.active {
    @apply translate-x-0;
}

.leaflet-container {
    @apply w-full h-full z-0;
}

.leaflet-div-icon a {
    @apply text-white;
}

.leaflet-control-zoom {
    @apply select-none;
}

.leaflet-control-attribution {
    @apply text-right;
    max-width: 80vw;
}

.content-container a:hover {
    @apply underline;
}

.links-container a {
    @apply text-blue-700;
}

.links-container a:hover {
    @apply underline;
}

.links-container a:first-child {
    @apply ml-3/2;
}

.share-container svg {
    @apply w-8 h-8;
}

.info-message-container > svg {
    @apply w-4 h-4 mt-2 mr-4;
}

.message-close-container > svg {
    @apply h-6 w-6 text-blue-800 cursor-pointer;
}

.message-close-container > svg:hover {
    @apply opacity-75;
}

.entries-container svg {
    @apply w-6 h-6;
}

.entries-container .entry:last-child {
    @apply mb-0 border-b-0;
}

.cookie-consent-container {
    max-width: 80vw;
}

.map {
    @apply w-full h-full bg-gray-700 bg-opacity-5 block;
}

.map .active-marker a {
    @apply border-3 opacity-90;
}

.no-js .loading-spinner span {
    @apply hidden;
}

.external-map-link-wrapper svg {
    @apply h-4 w-4 mr-1 text-gray-800 inline-block;
}

.place-creation-wrapper svg {
    @apply h-4 w-4 mr-3/2 text-gray-800 inline-block;
}

.suggestion-form svg {
    @apply w-full;
}

.way-round {
    @apply block text-left;
    direction: rtl;
    unicode-bidi: bidi-override;
}
